import React, { Component } from 'react';
import PropTypes from "prop-types";
import { AddOnCardV2, AddOnContainerV2, AddOnCardV2Mobile } from './styles';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';

const addOnPayload = [{
  url: '/images/PricingPageImages/Starter.svg',
  name: 'Core',
  rupee: "$4.17",
  rupees: "/Mo",
  description: '$50/user per Year',
  descriptionmore: 'You Save $10/year per User',
  button: 'Get Started',
  pointheading: 'Access to :',
  subpointA: 'Personalized Dashboards',
  subpointB: 'Activity and Wellness Challenges',
  subpointC: 'On-demand Fitness Videos',
  subpointD: 'On-demand Nutrition Recipes',
  subpointE: 'Wellness Gamification',
  subpointF: 'Email & Push Notifications',
  subpointG: 'Knowledge Base Support',
}, {
  url: '/images/PricingPageImages/core.svg',
  name: 'Premium',
  rupee: "$5.00",
  rupees: "/Mo",
  description: '$60/user per Year',
  descriptionmore: 'You Save $12/year per User',
  button: 'Get Started',
  pointheading: 'Everything in Core, Plus :',
  subpointA: 'Live Lunch & Learn Events',
  subpointB: 'Manage Company Events',
  subpointC: 'On-demand Wellness Events',
  subpointD: 'Real-Time Health Data Analysis',
  subpointE: 'View Potential Health Concerns',
  subpointF: 'Monthly Wellness Newsletter',
  subpointG: 'Global Leaderboard',
}, {
  url: '/images/PricingPageImages/Premium.svg',
  name: `Enterprise`,
  rupee: "$5.83",
  rupees: "/Mo",
  description: '$70/user per Year',
  descriptionmore: 'You Save $14/year per User',
  button: 'Get Started',
  pointheading: 'Everything in Premium, Plus :',
  subpointA: 'Customizable Company Rewards',
  subpointB: 'Track Rewards Spending',
  subpointC: 'Manage Biometric Reporting',
  subpointD: 'Biometric Data Analysis',
  subpointE: 'Upload Benefits Documents',
  subpointF: 'Single Sign-On',
  subpointG: 'Dedicated Account Manager',
}];

class AddOnSectionNew extends Component {
  constructor() {
    super();
    this.state = {
      dropDown: false,
    }
  }
  displayMore() {
    this.setState({ dropDown: !this.state.dropDown })
  }
  render() {
    const {dropDown} = this.state;
    return (
      <AddOnContainerV2>
        <div className="web-cards" style={{ "margin-top": "0px" }}>
          {
            addOnPayload.map((item, index) => (
              <AddOnCardV2 key={index}>
                <div>
                  <h1>{item.name}</h1>
                  <LazyImage src={ImageUrl + item.url} />
                  <div className='rupee-section'>
                    <h3>{item.rupee}</h3>
                    <h6><sub>{item.rupees}</sub></h6>
                  </div>
                  <p>{item.description}</p>
                  <p className='descmain'>
                    <div className='desc-letter'>{item.descriptionmore}</div>
                  </p>
                  <p style={{ "display": "inline-block", "margin-right": "5px" }}>{item.amount} </p>
                  <span>{item.user}</span>
                </div>
                <div className='button'>
                  <div onClick={() => this.props.history.push('/contact')}>{item.button}</div>
                </div>
                <hr className='hr-line' />
                <div>
                  <p className='pointhead'>{item.pointheading}</p>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointA}</p>
                  </div>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointB}</p>
                  </div>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointC}</p>
                  </div>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointD}</p>
                  </div>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointE}</p>
                  </div>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointF}</p>
                  </div>
                  <div className='point-wrap'>
                    <LazyImage div='tick-img' src={ImageUrl + '/images/whitetick.svg'} />
                    <LazyImage div='tick-img-white' src={ImageUrl + '/images/tickimagewhite.svg'} />
                    <p className='subpoint1'>{item.subpointG}</p>
                    <div className='space' />
                  </div>
                </div>
              </AddOnCardV2>
            ))
          }
        </div>

        <div className="cards" style={{ "margin-top": "0px" }}>
          {
            addOnPayload.map((item, index) => (
              <AddOnCardV2Mobile key={index} onClick={() => this.displayMore()} active={dropDown ? 1 : 0}>
                <div>
                  <h1>{item.name}</h1>
                  <LazyImage src={ImageUrl + item.url} />
                  <div className='rupee-section'>
                    <h3>{item.rupee}</h3>
                    <h6><sub>{item.rupees}</sub></h6>
                  </div>
                  <p>{item.description}</p>
                  <p className='descmain'>
                    <div className='desc-letter'>{item.descriptionmore}</div>
                  </p>
                  <p style={{ "display": "inline-block", "margin-right": "5px" }}>{item.amount} </p>
                  <span>{item.user}</span>
                </div>
                <div className='button'>
                  <div onClick={() => this.props.history.push('/contact')}>{item.button}</div>
                </div>
                <div className='to-seemore'>
                  {this.state.dropDown ?  
                    <button onClick={() => this.displayMore()}>Click to See Less</button> 
                    :
                    <button onClick={() => this.displayMore()}>Click to See More</button>
                  }
                </div>
                {/* <hr className='hrr-line'></hr> */}
                {this.state.dropDown ?
                  <div className='display-points'>
                    <hr className='hrr-line' />
                    <p className='pointhead'>{item.pointheading}</p>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointA}</p>
                    </div>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointB}</p>
                    </div>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointC}</p>
                    </div>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointD}</p>
                    </div>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointE}</p>
                    </div>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointF}</p>
                    </div>
                    <div className='point-wrap'>
                      {/* <img className='tick-img' src='/public/images/whitetick.svg'></img> */}
                      <img className='tick-img-white' src='/public/images/tickimagewhite.svg' />
                      <p className='subpoint1'>{item.subpointG}</p>
                      <div className='space' />
                    </div>
                  </div> : null}
              </AddOnCardV2Mobile>
            ))
          }
        </div>
      </AddOnContainerV2>
    )
  }
}

AddOnSectionNew.propTypes = {
  history: PropTypes.object
};

export default AddOnSectionNew;